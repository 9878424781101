
import { defineComponent } from "vue";
import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";
import { code4 } from "./data";

const cityOptions = ["Shanghai", "Beijing", "Guangzhou", "Shenzhen"];
export default defineComponent({
  name: "indeterminate",
  data() {
    return {
      checkAll: false,
      checkedCities: ["Shanghai", "Beijing"],
      cities: cityOptions,
      isIndeterminate: true
    };
  },
  methods: {
    handleCheckAllChange(val) {
      this.checkedCities = val ? cityOptions : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      const checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.cities.length;
    }
  },
  components: {
    CodeHighlighter
  },
  setup() {
    return {
      code4
    };
  }
});
