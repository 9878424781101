
import { defineComponent } from "vue";
import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";
import { code5 } from "./data";

const cityOptions = ["Shanghai", "Beijing", "Guangzhou", "Shenzhen"];
export default defineComponent({
  name: "button-style",
  data() {
    return {
      checkboxGroup1: ["Shanghai"],
      checkboxGroup2: ["Shanghai"],
      checkboxGroup3: ["Shanghai"],
      checkboxGroup4: ["Shanghai"],
      cities: cityOptions
    };
  },
  components: {
    CodeHighlighter
  },
  setup() {
    return {
      code5
    };
  }
});
